import {http} from './config';
import {httpAuth} from './config';

export default {
    register: (user) => {
        return httpAuth.post('v1/oauth/register', user);
    },
    security: (token) => {
        return httpAuth.get(`v1/security/login/${token}`);
    },
    login: (user) => {
        return httpAuth.post('v1/oauth/token', user);
    },
    loadSession: (token) => {
        return httpAuth.get('v1/oauth/check', token);
    },
    refreshToken: (token) => {
        return httpAuth.post('v1/oauth/refresh', token)
    },
    google: (googleUser) => {
        return httpAuth.get('login/google/callback', {params: googleUser});
    },
    listRealtor: (allotment_id) => {
        return http.get('api/v1/loteamentos/corretores/' + allotment_id);
    },
    permissions: (allotment_id) => {
        return http.get('api/v1/usuarios/permissao/' + allotment_id);
    },
    create: (user) => {
        return http.post('api/v1/usuarios', user);
    },
    show: (user_id) => {
        return httpAuth.get('v1/usuarios/' + user_id);
    },
    update: (user) => {
        return http.put('api/v1/usuarios/dados-pessoais/' + user.id, user);
    },
    showByCompanyWithoutPaginate: (companyId) => {
        return httpAuth.get(`v1/oauth/showPorEmpresa/${companyId}`);
    },
    showByCompany: (companyId, filter, page = 1) => {
        return httpAuth.get('v1/usuarios/companhia/' + companyId + '?page=' + page, {params: {'filtro': filter}});
    },
    recoverPassword: (email) => {
        return httpAuth.post('v1/password/email', email);
    },
    resetPassword: (password) => {
        return httpAuth.post('v1/password/reset', password);
    },
    resetPasswordLogged: (dates) => {
        return httpAuth.post('v1/usuarios/reset/password', dates);
    },
    sendEmailRegister: (email) => {
        return httpAuth.post('v1/password/email/first', email);
    },
    listPhoneType: () => {
        return httpAuth.get('v1/usuarios/tipoTelefones');
    },
    listAll:() => {
        return http.get('api/v1/usuarios');
    },
    import: (file, allotment_id, notify = false) => {
        const formData = new FormData();
        formData.append('file', file);
        return http.post('api/v1/importar/usuarios', formData, {
            headers: {
                "allotment-id": allotment_id,
                "Content-Type": "multipart/form-data",
                "notify": notify,
            }
        });
    }
}
