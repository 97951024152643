import {http} from './config';

export default {
    getRedirectAssign()
    {
        return http.get('api/v1/configuracaoClickSign/assinaturas');
    },
    index: () => {
        return http.get("api/v1/configuracaoClickSign");
    },
    create: (data) => {
        return http.post("api/v1/configuracaoClickSign", data);
    },
    view: (id) => {
        return http.get(`api/v1/configuracaoClickSign/${id}`);
    },
    edit: (data) => {
        return http.put(`api/v1/configuracaoClickSign/${data.id}`, data);
    }
}