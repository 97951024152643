import {createWebHistory, createRouter} from 'vue-router';
import beforeEach from './beforeEach';
import {trackRouter} from 'vue-gtag-next';

const routes = [
    {
        path: '/',
        name: 'App',
        component: () => import(/* webpackChunkName: "LayoutApp" */ '@/layouts/App'),
        children: [
            {
                path: '/',
                name: 'Home',
                component: () => import(/* webpackChunkName: "Home" */'@/pages/home/homeComponent'),
            },
            {
                path: '/loteamento',
                name: 'IndexAllotmant',
                component: () => import(/* webpackChunkName: "IndexAllotment" */ '@/pages/allotment/indexAllotmentComponent'),
            },
            {
                path: '/companhia',
                name: 'Company',
                component: () => import(/* webpackChunkName: "IndexCompany" */'@/pages/company/indexCompanyComponent'),
            },
            {
                path: '/situacao',
                name: 'IndexSituation',
                component: () => import(/* webpackChunkName: "IndexSituation" */'@/pages/situation/indexSituationComponent'),
            },
            {
                path: '/tipo-propriedade',
                name: 'IndexPropertyTypes',
                component: () => import(/* webpackChunkName: "IndexPropertyType" */'@/pages/propertyTypes/indexPropertyTypesComponent'),
            },
            {
                path: '/lote',
                name: 'IndexLotes',
                component: () => import(/* webpackChunkName: "IndexLots" */'@/pages/lot/indexLotComponent'),
            },
            {
                path: '/formas-pagamentos',
                name: 'IndexPaymentMethods',
                component: () => import(/* webpackChunkName: "IndexPaymentMethods" */'@/pages/paymentMethods/indexPaymentMethodsComponent'),
            },
            {
                path: '/estadosCivis',
                name: 'IndexMaritalStatuses',
                component: () => import(/* webpackChunkName: "IndexPaymentMethods" */'@/pages/maritalStatuses/indexMaritalStatusesComponent'),
            },
            {
                path: '/tipo-reserva',
                name: 'IndexReserveTypes',
                component: () => import(/* webpackChunkName: "IndexReserveTypes" */'@/pages/reserveTypes/indexReserveTypesComponent'),
            },
            {
                path: '/reserva',
                name: 'IndexReserve',
                component: () => import(/* webpackChunkName: "IndexReserve" */'@/pages/reserve/indexReserveComponent'),
            },
            {
                path: '/usuario',
                name: 'IndexUser',
                component: () => import(/* webpackChunkName: "IndexUser" */'@/pages/user/indexUserComponent'),
            },
            {
                path: '/cliente',
                name: 'IndexClient',
                component: () => import(/* webpackChunkName: "IndexClient" */'@/pages/client/indexClientComponent'),
            },
            {
                path: '/dashboard-loteamento/:id',
                name: 'DashboardAllotment',
                component: () => import(/* webpackChunkName: "PaymentForms" */'@/pages/allotment/DashboardAllotmentComponent'),
            },
            {
                path: '/responsavel',
                name: 'indexResponsablePerTask',
                component: () => import(/* webpackChunkName: "IndexReserveTypes" */'@/pages/responsiblePerTasks/indexResponsiblePerTaskComponent'),
            },
            {
                path: '/comissoes',
                name: 'IndexCommission',
                component: () => import(/* webpackChunkName: "ListMoviments" */'@/pages/commissions/indexComissionComponent'),
            },
            {
                path: '/camposObrigatorios',
                name: 'indexAttributesValidations',
                component: () => import(/* webpackChunkName: "indexAttributesValidations" */'@/pages/atributesValidationsComponent/indexAtributesComponent'),
            },
            {
                path: '/painel-administrativo',
                name: 'PainelAdministrativo',
                component: () => import(/* webpackChunkName: "PainelAdministrativo" */'@/pages/panel/indexComponent'),
            },
            {
                path: '/cronograma/:id',
                name: 'Cronograma',
                component: () => import(/* webpackChunkName: "PaymentForms" */'@/pages/cronogramaGrantt/GanttComponent'),
            },
            {
                path: '/planos',
                name: 'PlanosIndex',
                component: () => import(/* webpackChunkName: "PlanosIndex" */'@/pages/plan/indexComponent'),
            },
            {
                path: '/reajustes',
                name: 'IndexReajustes',
                component: () => import(/* webpackChunkName: "IndexReajustes" */'@/pages/readjustment/indexReadjustmentComponent'),
            },
            {
                path: '/documentos',
                name: 'IndexDocumentDrive',
                component: () => import(/* webpackChunkName: "IndexDocumentDrive" */'@/pages/driveDocuments/indexDriveDocumentsComponent'),
            },
            {
                path: '/corretores/reservas',
                name: 'indexRealtorsReserves',
                component: () => import(/* webpackChunkName: "indexRealtorsReserves" */'@/pages/reserve/indexRealtorsReserves'),
            },
            {
                path: '/clientes/dashboard',
                name: 'dashboardClient',
                component: () => import(/* webpackChunkName: "dashboardClient" */'@/pages/client/dashboardClientComponent'),
            },
            {
                path: '/templates/proposta',
                name: 'indexTemplatesProposal',
                component: () => import(/* webpackChunkName: "ProposalTemplate" */'@/pages/templatesProposal/indexTemplateProposalComponent'),
            },
            {
                path: '/propostas',
                name: 'IndexProposal',
                component: () => import(/* webpackChunkName: "Proposal" */'@/pages/proposal/indexProposalComponent'),
            },
            {
                path: '/grupos-publicos',
                name: 'indexPublicGroup',
                component: () => import('@/pages/groupPublic/indexPublicGroup'),
            },
            {
                path: '/configuracao-assinatura-eletronica',
                name: 'indexConfigClickSign',
                component: () => import('@/pages/ConfigClickSign/indexConfigClickSignComponent'),
            },
            {
                path: '/configuracao-calculadora-financiamento/index',
                name: 'indexConfigSimulator',
                component: () => import(/* webpackChunkName: "SimulatorConfig" */'@/pages/configSimulator/indexConfigSimulatorComponent'),
            },
            {
                path: '/configuracao-calculadora-financiamento/:action/:id?',
                name: 'createConfigSimulator',
                component: () => import(/* webpackChunkName: "CreateSimulatorConfig" */'@/pages/configSimulator/createConfigSimulatorComponent'),
            },
            {
                path: '/configuracao-area-publica/index',
                name: 'indexConfigPublicArea',
                component: () => import(/* webpackChunkName: "IndexConfigPublicArea "*/'@/pages/configPublicArea/indexConfigPublicAreaComponent'),
            },
            {
                path: '/configuracao-area-publica/:action/:id?',
                name: 'createConfigPublicArea',
                component: () => import(/* webpackChunkName: "CreateSimulatorConfig" */'@/pages/configPublicArea/createConfigPublicAreaComponent'),
            },
            {
                path: '/tipos-de-anexo',
                name: 'IndexDriveFileTypes',
                component: () => import(/* webpackChunkName: "IndexDriveFileTypes" */'@/pages/driveFilesType/indexDriveFileTypeComponent'),
            },
        ],
    },
    {
        path: '/login',
        name: 'Public',
        component: () => import(/* webpackChunkName: "LayoutPublic" */'@/layouts/Public'),
        children: [
            {
                path: '/',
                name: 'Login',
                component: () => import(/* webpackChunkName: "Login" */'@/pages/login/loginComponent'),
            },
            {
                path: '/singup',
                name: 'Singup',
                component: () => import(/* webpackChunkName: "Logout" */'@/pages/login/singupComponent'),
            },
            {
                path: '/recuperar-senha',
                name: 'RecoverPassword',
                component: () => import(/* webpackChunkName: "RecoverPassword" */'@/pages/password/recoverPasswordComponent'),
            },
            {
                path: '/redefinir-senha/:token/:email',
                name: 'ResetPassword',
                component: () => import(/* webpackChunkName: "ResetPassword" */'@/pages/password/resetPasswordComponent'),
            },
            {
                path: '/cadastrar-senha/:token/:email',
                name: 'CreatePassword',
                component: () => import(/* webpackChunkName: "CreatePassword" */'@/pages/password/resetPasswordComponent'),
            },
            {
                path: '/login/google',
                name: 'Google',
                component: () => import(/* webpackChunkName: "Logout" */'@/pages/login/googleSingupComponent'),
            },
            {
                path: '/cliente-publico/:companyId/:userId',
                name: 'PublicClient',
                component: () => import(/* webpackChunkName: "publicClient" */'@/pages/client/publicClientComponent'),
            },
            {
                path: '/lote-publico/:companyId/:allotmentId',
                name: 'PublicLot',
                component: () => import(/* webpackChunkName: "publicLot" */'@/pages/lot/publicLotComponent'),
            },
            {
                path: '/corretores/:groupid',
                name: 'indexAreaPublica',
                component: () => import('@/pages/publicArea/indexPublicAreaComponent'),
            },
            {
                path: '/mapas/:groupid',
                name: 'indexPublicMapas',
                component: () => import('@/pages/publicArea/indexPublicMapasComponent'),
            },
        ],
    },
    {
        path: '/security/:token',
        name: 'SecurityLogin',
        component: () => import(/* webpackChunkName: "SecurityLogin" */ '@/pages/login/securityComponent'),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(beforeEach);
trackRouter(router);
export default router;
